import { Route } from '@angular/router';
import { unauthenticated, authenticated } from 'auth';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    canActivate: [unauthenticated('/')],
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    canActivate: [authenticated('/auth')],
    loadChildren: () =>
      import('./modules/questions/questions.module').then(
        (m) => m.QuestionsModule,
      ),
  },
  {
    path: 'jobs',
    canActivate: [authenticated('/auth')],
    loadChildren: () =>
      import('./modules/job/job.module').then((m) => m.JobModule),
  },
  {
    path: 'question-types',
    canActivate: [authenticated('/auth')],
    loadChildren: () =>
      import('./modules/question-types/question-types.module').then(
        (m) => m.QuestionTypesModule,
      ),
  },
  { path: '**', redirectTo: '' },
];
